import { render, staticRenderFns } from "./BonusImageConfirm.vue?vue&type=template&id=ba1b39c8&scoped=true"
import script from "./BonusImageConfirm.vue?vue&type=script&lang=js"
export * from "./BonusImageConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba1b39c8",
  null
  
)

export default component.exports